var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("fclt-plugin-status", {
        ref: "fcltPluginStatus",
        attrs: {
          title: "RSE 상태정보",
          fcltId: this.fcltData.fcltId,
          statHederInfo: _vm.statHederInfo
        }
      }),
      _c("div", { staticClass: "dk-card" }, [
        _c(
          "table",
          { staticClass: "el-table el-table--mini" },
          [
            _c("tr", [
              _c("th", [_vm._v("시설물 명칭")]),
              _c("td", [_vm._v(_vm._s(_vm.fcltData.fcltName))])
            ]),
            _c("tr", [
              _c("th", [_vm._v("시설물 유형")]),
              _c("td", [_vm._v(_vm._s(_vm.fcltData.fcltTypeName))])
            ]),
            _c("tr", [
              _c("th", [_vm._v("도로명 주소")]),
              _c("td", [_vm._v(_vm._s(_vm.fcltData.setRoadAddr))])
            ]),
            _c("tr", [
              _c("th", [_vm._v("지번 주소")]),
              _c("td", [_vm._v(_vm._s(_vm.fcltData.setNumberAddr))])
            ]),
            _c("tr", [
              _c("th", [_vm._v("경도")]),
              _c("td", [_vm._v(_vm._s(_vm.fcltData.gisLng))])
            ]),
            _c("tr", [
              _c("th", [_vm._v("위도")]),
              _c("td", [_vm._v(_vm._s(_vm.fcltData.gisLat))])
            ]),
            _vm._l(_vm.checkFcltData, function(ftData, index) {
              return _c("tr", { key: index }, [
                _c("th", [_vm._v(_vm._s(ftData.label))]),
                _c("td", [_vm._v(_vm._s(_vm.getFcltDataValue(ftData)))])
              ])
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }