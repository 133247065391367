var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dk-card" }, [
    _c("div", { staticClass: "dk-card-title" }, [
      _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
    ]),
    _c("table", { staticClass: "el-table el-table--mini" }, [
      _c(
        "tr",
        _vm._l(_vm.statViewList, function(item) {
          return _c("th", { key: item.key }, [
            _vm._v("\n        " + _vm._s(item.name) + "\n      ")
          ])
        }),
        0
      ),
      _c(
        "tr",
        _vm._l(_vm.statViewList, function(item) {
          return _c(
            "td",
            {
              key: item.key,
              staticStyle: { width: "20%", "text-align": "left" }
            },
            [
              _c("el-tag", { attrs: { type: item.code } }, [
                _vm._v("\n          " + _vm._s(item.text) + "\n        ")
              ])
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }