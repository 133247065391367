<template>
  <div class="dk-card">
    <div class="dk-card-title">
      {{ title }}
    </div>
    <table class="el-table el-table--mini">
      <tr>
        <th v-for="item in statViewList" :key="item.key">
          {{ item.name }}
        </th>
        <!-- <th style="width:20%;text-align:center;">
                        통신 상태
                    </th>
                    <th style="width:20%;text-align:center;">
                        모듈 전원
                    </th>
                    <th style="width:20%;text-align:center;">
                        문 열림
                    </th>
                    <th style="width:20%; text-align:center;">
                        팬 동작
                    </th>
                    <th style="width:20%; text-align:center;">
                        히터 동작
                    </th> -->
      </tr>
      <tr>
        <td v-for="item in statViewList" :key="item.key" style="width:20%; text-align:left;">
          <el-tag :type="item.code">
            {{ item.text }}
          </el-tag>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  components: {},
  name: "fclt-plugin-status",
  label: "",
  created() {},
  destroyed() {},
  mounted() {},
  data() {
    return {
      statCodeInfo: {
        ERR: "danger",
        OFF: "warning",
        ON: "success",
      },
      fcltStatus: {},
      statViewInfo: {},
      statViewList: [],
      statInfo: {
        comm: {
          key: "comm",
          name: "통신 상태",
          textInfo: {
            ERR: "에러",
            OFF: "꺼짐",
            ON: "정상",
          },
        },
      },
    }
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    fcltId: {
      type: String,
      required: true,
    },
    statHederInfo: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async getFcltStatusInfo(fcltId) {
      const _this = this
      await _this.$store.dispatch("fcltStatus/getFcltStatus", { fcltId })
      const fcltStatus = _this.$store.getters["fcltStatus/getFcltStatus"]
      if (fcltStatus && fcltStatus.length > 0) {
        _this.fcltStatus = fcltStatus[0]
      } else {
        _this.fcltStatus = {}
      }
    },
    setFcltStatusInfo() {
      const _this = this
      this.statViewList = []
      let commViewInfo = {
        key: "comm",
        name: "",
        code: "",
        text: "",
      }
      commViewInfo.name = _this.statInfo.comm.name

      // 통신 성공시
      if (_this.fcltStatus.statusFlag === true) {
        commViewInfo.code = _this.statCodeInfo.ON
        commViewInfo.text = _this.statInfo.comm.textInfo.ON
        _this.statViewList.push(commViewInfo)

        _this.statHederInfo.forEach((statHeder) => {
          let statViewInfo = {
            key: "",
            name: "",
            code: "",
            text: "",
          }

          statViewInfo.key = statHeder.key
          statViewInfo.name = statHeder.name

          // 상태정보에 키가 없을 시
          if (typeof _this.fcltStatus.status[statHeder.key] === undefined) {
            statViewInfo.code = _this.statCodeInfo.ERR
            statViewInfo.text = statHeder.textInfo.ERR
          }
          // 상태정보 TRUE
          else if (_this.fcltStatus.status[statHeder.key] === true) {
            statViewInfo.code = _this.statCodeInfo.ON
            statViewInfo.text = statHeder.textInfo.ON
          }
          // 상태정보 FALSE
          else {
            statViewInfo.code = _this.statCodeInfo.OFF
            statViewInfo.text = statHeder.textInfo.OFF
          }

          _this.statViewList.push(statViewInfo)
        })
      }
      // 통신 실패시
      else {
        let commViewInfo = {
          key: "comm",
          name: "",
          code: "",
          text: "",
        }

        // 통신 상태
        commViewInfo.name = _this.statInfo.comm.name
        commViewInfo.code = _this.statCodeInfo.OFF
        commViewInfo.text = _this.statInfo.comm.textInfo.OFF

        // 상태정보
        _this.statHederInfo.forEach((statHeder) => {
          let statViewInfo = {
            key: "",
            name: "",
            code: "",
            text: "",
          }
          statViewInfo.key = statHeder.key
          statViewInfo.name = statHeder.name
          statViewInfo.code = _this.statCodeInfo.ERR
          statViewInfo.text = statHeder.textInfo.ERR

          _this.statViewList.push(statViewInfo)
        })
      }
    },
    async updateFcltStatusInfo(fcltId) {
      const _this = this

      await _this.getFcltStatusInfo(_this.fcltId)
      _this.setFcltStatusInfo()
    },
  },
}
</script>
